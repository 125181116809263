import https from "./https"
import store from "@/store"

const group = {
  getGroups(params) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/groups', {params})
  },
  getGroup(id) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/groups/' + id)
  },
  checkGroup(id) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/groups/' + id + '/check')
  },
  leaveGroup(id) {
    const organization = store.state.general.organization
    return https.get('admin/organizations/' + organization.id + '/groups/' + id + '/leave')
  },
  updateGroup(group_id , payload) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id + '/groups/' + group_id

    return https.put(url, payload)
  },
  getGroupCustomers(group_id, params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id + '/groups/' + group_id + '/customers'

    return https.get(url, {params})
  },
  getGroupConversationRecord(group_id, params) {
    const organization = store.state.general.organization
    const url = 'admin/organizations/' + organization.id + '/groups/' + group_id + '/conversation-record'

    return https.get(url, {params})
  },
}

export default group
