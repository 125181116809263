<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'GroupbuyingsList',
              }"
              >團購管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>{{
              isEdit ? "編輯團購" : "我要開團"
            }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
      <div class="row">
        <div class="col-12 col-xl-8">
          <h4 class="mb-2 font-weight-bold">
            {{ isEdit ? "編輯團購" : "我要開團" }}
          </h4>

          <section class="mb-5">
            <div class="h5">Step 1: 請選擇開團群組</div>
            <b-form-group
              v-if="!isEdit"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="選擇分店"
              label-for="input-1"
            >
              <div>
                <b-spinner
                  small
                  v-if="isFetchFirstLayerBranches"
                  variant="secondary"
                ></b-spinner>

                <b-form-select
                  v-else
                  class="form-control form-control-sm col-12 col-xl-6"
                  v-model="firstBranch"
                  :state="v$.form.branch_id.$error ? false : null"
                  :disabled="isEdit"
                  @change="handleFirstBranch"
                >
                  <b-form-select-option :value="null" disabled>
                    請選擇分店
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="branch in mapFirstLayerBranches"
                    :key="branch.id"
                    :value="branch"
                  >
                    {{ branch.name }}
                  </b-form-select-option>
                </b-form-select>
              </div>
              <div>
                <b-spinner
                  small
                  v-if="isFetchSecondLayerBranches"
                  variant="secondary"
                ></b-spinner>

                <b-form-select
                  v-if="
                    mapSecondLayerBranches && mapSecondLayerBranches.length > 1
                  "
                  class="form-control form-control-sm col-12 col-xl-6 mt-2"
                  v-model="secondBranch"
                  :state="v$.form.branch_id.$error ? false : null"
                  :disabled="isEdit"
                  @change="handleSecondBranch"
                >
                  <b-form-select-option :value="null" disabled>
                    請選擇分店
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="branch in mapSecondLayerBranches"
                    :key="branch.id"
                    :value="branch"
                  >
                    {{ branch.name }}
                  </b-form-select-option>
                </b-form-select>
              </div>

              <div>
                <b-spinner
                  small
                  v-if="isFetchThirdLayerBranches"
                  variant="secondary"
                ></b-spinner>

                <b-form-select
                  v-if="
                    mapThirdLayerBranches && mapThirdLayerBranches.length > 1
                  "
                  class="form-control form-control-sm col-12 col-xl-6 mt-2"
                  v-model="thirdBranch"
                  :state="v$.form.branch_id.$error ? false : null"
                  :disabled="isEdit"
                  @change="handleThirdBranch"
                >
                  <b-form-select-option :value="null" disabled>
                    請選擇分店
                  </b-form-select-option>
                  <b-form-select-option
                    v-for="branch in mapThirdLayerBranches"
                    :key="branch.id"
                    :value="branch"
                  >
                    {{ branch.name }}
                  </b-form-select-option>
                </b-form-select>
              </div>

              <b-form-invalid-feedback :state="!v$.form.branch_id.$error">
                此欄位為必填
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              v-if="form.branch_id || isEdit"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="開團群組"
              label-for="input-1"
            >
              <template v-if="!isEdit">
                <b-spinner
                  small
                  v-if="isFetchGroup"
                  variant="secondary"
                ></b-spinner>

                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="form.group_ids"
                >
                  <b-form-checkbox
                    v-for="group in groupOptions"
                    :key="group.id"
                    :value="group.id"
                    >{{ group.name }}</b-form-checkbox
                  >
                </b-form-checkbox-group>

                <b-form-invalid-feedback :state="!v$.form.group_ids.$error">
                  此欄位為必填
                </b-form-invalid-feedback>
              </template>
              <template v-else>
                <div v-if="this.selectedGroup">
                  {{ this.selectedGroup.name }}
                </div>
              </template>
            </b-form-group>
          </section>
          <section class="mb-5">
            <div class="h5">Step 2: 請編輯團購設定</div>

            <div class="mb-4">
              <b-form-group
                label-cols="12"
                content-cols="12"
                content-cols-lg="7"
                label-size="sm"
                label="請輸入商品編號，輸入完畢後，請按確認"
              >
                <div v-if="useWhichProductSource === 'product_whitelist'">
                  <b-form-select
                    class="form-control form-control-sm"
                    :state="v$.form.product_ids.$error ? false : null"
                    :disabled="isEdit"
                    v-model="selectedProduct"
                    @change="handleSelectProduct"
                  >
                    <b-form-select-option :value="null" disabled>
                      請選擇商品
                    </b-form-select-option>
                    <b-form-select-option
                      v-for="productOption in productOptions"
                      :key="productOption.id"
                      :value="productOption"
                    >
                      ({{ productOption.sale_page_code }})
                      {{ productOption.title }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback :state="!v$.form.product_ids.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>
                <div v-if="useWhichProductSource === 'all'">
                  <b-input-group>
                    <b-form-input
                      placeholder="請輸入商品編號"
                      v-model="code"
                      :disabled="isEdit"
                      :state="v$.form.product_ids.$error ? false : null"
                      @keyup.enter="handleSearchProducts"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        :disabled="isEdit || !code"
                        @click="handleSearchProducts"
                        >確認</b-button
                      >
                    </b-input-group-append>
                    <b-form-invalid-feedback
                      :state="!v$.form.product_ids.$error"
                    >
                      此欄位為必填
                    </b-form-invalid-feedback>
                  </b-input-group>

                  <ProductViewer
                    :loading="isFetchProduct"
                    :product="selectedProduct"
                  />
                </div>
              </b-form-group>
            </div>

            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="商品剩餘總數"
              label-for="salableQuantity"
            >
              {{ salableQuantity }}
            </b-form-group>

            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="本群分配庫存"
              label-for="max_quantity"
              v-if="this.isEdit && groupbuying && (groupbuying.type === 3 || groupbuying.type === 4)"
            >
              <b-form-input
                id="max_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 80px"
                v-model="form.max_quantity"
                :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                readonly
              ></b-form-input>
              <b-form-invalid-feedback
                :state="!v$.form.max_quantity.required.$invalid"
              >
                此欄位為必填
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                :state="!v$.form.max_quantity.lessThanSalableQuantity.$invalid"
              >
                需小於商品可賣量
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- <b-form-group
            label-cols="6"
            label-cols-lg="2"
            label-size="sm"
            label="商品總數上限"
            label-for="input-1"
          >
            <div>
              <b-form-input
                id="input-1"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 80px"
                :state="v$.form.max_quantity.$error ? false : null"
                v-model="form.max_quantity"
                :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
              ></b-form-input>
              商品可賣量：{{ salableQuantity }}
            </div>
            <b-form-invalid-feedback
              :state="!v$.form.max_quantity.required.$invalid"
            >
              此欄位為必填
            </b-form-invalid-feedback>
            <b-form-invalid-feedback
              :state="!v$.form.max_quantity.lessThanSalableQuantity.$invalid"
            >
              需小於商品可賣量
            </b-form-invalid-feedback>

            <div class="form-check">
              <input
                id="is_force_confirmed"
                class="form-check-input"
                type="checkbox"
                v-model="form.is_force_confirmed"
              />
              <label for="is_force_confirmed" class="form-check-label">
                若訂購數量達標，強制結單
              </label>
            </div>
          </b-form-group> -->
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="每人最高購買數"
              label-for="once_max_quantity"
            >
              <b-form-input
                id="once_max_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 80px"
                v-model="form.once_max_quantity"
                :formatter="(value) => (value < 0 ? 0 : Math.round(value))"
                :disabled="isEdit && !isStatusPrepared"
                :state="v$.form.once_max_quantity.$error ? false : null"
                readonly
              ></b-form-input>
              <b-form-invalid-feedback
                :state="!v$.form.once_max_quantity.less.$invalid"
              >
                需小於商品總數上限
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="每人最低購買數"
              label-for="once_quantity"
            >
              <b-form-input
                id="once_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 80px"
                v-model="form.once_quantity"
                :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                :disabled="isEdit && !isStatusPrepared"
                :state="v$.form.once_quantity.$error ? false : null"
                readonly
              ></b-form-input>
              <b-form-invalid-feedback
                :state="!v$.form.once_quantity.less.$invalid"
              >
                需小於每人最高購買數
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="下單數量遞增數"
              label-for="once_increase_quantity"
            >
              <b-form-input
                id="once_increase_quantity"
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                style="width: 80px"
                v-model="form.once_increase_quantity"
                :formatter="(value) => (value < 1 ? 1 : Math.round(value))"
                :disabled="isEdit && !isStatusPrepared"
                :state="v$.form.once_increase_quantity.$error ? false : null"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              content-cols="12"
              content-cols-lg="6"
              label="開始時間"
            >
              <div class="d-flex">
                <div class="w-50">
                  <datepicker
                    placeholder="Select Date"
                    v-model="form.start_at.date"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :disabled="isEdit && !isStatusPrepared"
                    :disabledDates="disabledDates"
                  ></datepicker>
                  <b-form-invalid-feedback
                    :state="!v$.form.start_at.date.required.$error"
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>

                <div class="ml-2 w-50">
                  <vue-timepicker
                    format="HH:mm"
                    v-model="form.start_at.time"
                    :input-class="[
                      'form-control',
                      {
                        'is-invalid':
                          v$.form.start_at.time.HH.$error ||
                          v$.form.start_at.time.mm.$error,
                      },
                    ]"
                    :disabled="isEdit && !isStatusPrepared"
                  ></vue-timepicker>
                  <b-form-invalid-feedback
                    :state="
                      !v$.form.start_at.time.HH.$error ||
                      !v$.form.start_at.time.mm.$error
                    "
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>
              </div>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              content-cols="12"
              content-cols-lg="6"
              label="截止時間"
              description="*達截止時間系統將自動結團"
            >
              <div class="d-flex">
                <div class="w-50">
                  <datepicker
                    placeholder="Select Date"
                    v-model="form.end_at.date"
                    bootstrap-styling
                    format="yyyy-MM-dd"
                    :language="zh"
                    :input-class="{
                      'is-invalid': v$.form.start_at.$invalid,
                    }"
                    :disabledDates="disabledDates"
                  ></datepicker>
                  <b-form-invalid-feedback :state="!v$.form.end_at.date.$error">
                    此欄位為必填
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    :state="!v$.form.start_at.date.beforeEndAt.$invalid"
                  >
                    截止時間必須大於開團時間
                  </b-form-invalid-feedback>
                </div>

                <div class="ml-2 w-50">
                  <vue-timepicker
                    :input-class="[
                      'form-control',
                      {
                        'is-invalid':
                          v$.form.start_at.time.HH.$error ||
                          v$.form.start_at.time.mm.$error ||
                          v$.form.start_at.date.beforeEndAt.$invalid,
                      },
                    ]"
                    v-model="form.end_at.time"
                  ></vue-timepicker>
                  <b-form-invalid-feedback
                    :state="
                      !v$.form.end_at.time.HH.$error ||
                      !v$.form.end_at.time.mm.$error
                    "
                  >
                    此欄位為必填
                  </b-form-invalid-feedback>
                </div>
              </div>
            </b-form-group>

            <b-form-group
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="員工編號"
              label-for="tracking_code"
            >
              <b-form-input
                v-if="groupbuying && groupbuying.main_id !== null"
                id="tracking_code"
                class="mb-2 mr-sm-2 mb-sm-0 w-100 w-lg-35"
                value="總公司開團"
                disabled
              ></b-form-input>
              <b-form-input
                id="tracking_code"
                class="mb-2 mr-sm-2 mb-sm-0 w-100 w-lg-35"
                v-model="form.tracking_code"
                :formatter="
                  (value) => (value.length > 6 ? value.slice(0, 6) : value)
                "
                :disabled="isEdit"
                :state="v$.form.tracking_code.$error ? false : null"
                :style="{'display': (groupbuying && groupbuying.main_id !== null) ? 'none' : null}"
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="v$.form.tracking_code.required.$invalid"
              >
                此欄位為必填
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="v$.form.tracking_code.lengthSix.$invalid"
              >
                需為6碼數字
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              v-if="isEdit && groupbuying.type !== 1"
              label-cols="6"
              label-cols-lg="2"
              label-size="sm"
              label="順序"
              label-for="order"
            >
              <b-form-input
                id="order"
                class="mb-2 mr-sm-2 mb-sm-0"
                style="width: 170px"
                v-model="form.order"
                :disabled="groupbuying.type === 2"
                :state="v$.form.order.$error ? false : null"
              ></b-form-input>
              <b-form-invalid-feedback
                v-if="v$.form.order.required.$invalid"
              >
                此欄位為必填
              </b-form-invalid-feedback>
              <b-form-invalid-feedback
                v-if="v$.form.order.lengthTwo.$invalid"
              >
                需為2碼數字
              </b-form-invalid-feedback>
            </b-form-group>

            <!-- <b-form-group
            label-cols="6"
            label-cols-lg="2"
            label-size="sm"
            label="付款期限"
            label-for="input-1"
          >
            <div>
              結單後
              <b-form-input
                class="mb-2 mr-sm-2 mb-sm-0"
                type="number"
                v-model="form.payday_period"
                style="width: 80px"
                :state="
                  v$.form.payday_period && v$.form.payday_period.$error
                    ? false
                    : null
                "
                :formatter="(val) => (val < 1 ? 1 : val)"
              ></b-form-input>
              日後停止付款
            </div>
            <div>預計付款截止日：{{ estimatedEndDate }} 23:59</div>
          </b-form-group> -->
          </section>
        </div>
      </div>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-button
          class="mr-4"
          variant="outline-danger"
          @click="handleCancelGroupbuy"
          >{{ isEdit ? "取消編輯" : "取消開團" }}</b-button
        >
        <b-button variant="success" @click="handleSubmitGroupbuy">{{
          isEdit ? "確認編輯" : "確認開團"
        }}</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Datepicker from "vuejs-datepicker";
import VueTimepicker from "vue2-timepicker";
import {
  addDays,
  format,
  isBefore,
  set,
  subDays,
  isWithinInterval,
} from "date-fns";
import groupbuyApi from "../../../apis/groupbuy";
import groupApi from "../../../apis/group";
import branchApi from "../../../apis/branch";
import getHours from "date-fns/getHours/index";
import getMinutes from "date-fns/getMinutes/index";
import { zh } from "vuejs-datepicker/dist/locale";
import ProductViewer from "@/components/Page/Dashboard/ProductViewer";
import { GROUPBUYING_TYPE } from "./utils";

export default {
  components: { Datepicker, VueTimepicker, ProductViewer },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),

  data() {
    return {
      zh,
      isFetchProduct: false,
      isFetchFirstLayerBranches: false,
      isFetchSecondLayerBranches: false,
      isFetchThirdLayerBranches: false,
      isFetchGroup: false,
      isSubmmiting: false,
      selectedProduct: null,
      selectedGroup: null,
      groupOptions: null,
      disabledDates: { to: subDays(new Date(), 1) },
      productOption: null,
      productOptions: [],
      firstBranch: null,
      secondBranch: null,
      thirdBranch: null,
      firstLayerBranches: [],
      secondLayerBranches: [],
      thirdLayerBranches: [],
      form: {
        branch_id: null,
        group_ids: [],
        product_ids: null,
        once_max_quantity: 1, // 每人最高購買數
        once_quantity: 1, // 每人最低購買數
        once_increase_quantity: 1, // 下單數量遞增數
        is_force_confirmed: false, // 強制結單
        max_quantity: 0, // 商品總數上限
        tracking_code: null,
        start_at: {
          date: new Date(),
          time: {
            HH: "00",
            mm: "00",
          },
        },
        end_at: {
          date: new Date(),
          time: {
            HH: "23",
            mm: "59",
          },
        },
        payday_period: 0,
        order: String(1),
      },
      branchId: null,
      groupbuying: null,
      useWhichProductSource: "all",
      code: null,
      GROUPBUYING_TYPE,
    };
  },

  validations() {
    return {
      form: {
        branch_id: { required },
        group_ids: { required },
        product_ids: { required },
        tracking_code: {
          required,
          lengthSix: () => {
            if (!this.form.tracking_code) return true;
            return (
              this.form.tracking_code.length === 6 &&
              /^[0-9]*$/.test(this.form.tracking_code)
            );
          },
        },
        max_quantity: {
          required,
          lessThanSalableQuantity: () => {
            if (this.isEdit) return true;

            return this.salableQuantity
              ? this.salableQuantity >= this.form.max_quantity
              : true;
          },
        },
        once_max_quantity: {
          less: () => {
            return this.form.max_quantity
              ? Number(this.form.max_quantity) >=
                  Number(this.form.once_max_quantity)
              : true;
          },
        },
        once_quantity: {
          less: () => {
            return this.form.once_max_quantity
              ? Number(this.form.once_max_quantity) >=
                  Number(this.form.once_quantity)
              : true;
          },
        },
        once_increase_quantity: { required },
        start_at: {
          date: {
            required,
            beforeEndAt: () => {
              const startDate = set(new Date(this.form.start_at.date), {
                hours: this.form.start_at.time.HH,
                minutes: this.form.start_at.time.mm,
              });

              const endDate = set(new Date(this.form.end_at.date), {
                hours: this.form.end_at.time.HH,
                minutes: this.form.end_at.time.mm,
              });

              return isBefore(startDate, endDate);
            },
          },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        end_at: {
          date: { required },
          time: {
            HH: { required },
            mm: { required },
          },
        },
        order: {
          required,
          lengthTwo: () => {
            // 開團就不驗證這個 因為不用輸入
            if (!this.isEdit) return true;
            // 編輯草稿時只有type === 3 才有驗證必要
            if (this.groupbuying.type !== 3 && this.isEdit) return true;
            return (
              this.form.order.length <= 2 &&
              /^[0-9]*$/.test(this.form.order)
            );
          },
        },
      },
    };
  },

  async created() {
    this.getGroupbuyingConfig();

    if (this.isEdit) {
      await this.getGroupbuying();
    } else {
      await this.getFirstLayerBranches();
    }
  },

  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      currentBranch: (state) => state.currentBranch,
    }),
    isEdit() {
      return this.$route.name === "GroupbuyingsEdit";
    },
    estimatedEndDate() {
      return format(
        addDays(this.form.end_at.date, this.form.payday_period),
        "yyyy-MM-dd"
      );
    },
    status() {
      return this.groupbuying?.status.substring(0, 1);
    },
    isStatusPrepared() {
      return this.status === "0";
    },
    salableQuantity() {
      return this.selectedProduct?.sku_list[0]?.quantity;
    },
    mapFirstLayerBranches() {
      return this.mapBranches(this.firstLayerBranches);
    },
    mapSecondLayerBranches() {
      return this.mapBranches(this.secondLayerBranches);
    },
    mapThirdLayerBranches() {
      return this.mapBranches(this.thirdLayerBranches);
    },
  },

  watch: {
    "form.branch_id": async function (val) {
      if (val) {
        if (!this.isEdit) {
          await this.getGroups();
          this.selectedProduct = null;
          await this.getProducts();
        }
      }
    },

    selectedProduct: function (product) {
      /*
       * 編輯團購、檢視團購，這個最高、最低、遞增數要改從 groupbuyings 抓。
       *  -> 所以isEdit 為true 時就不從product 塞quantity 進this.form
       */
      if (product && !this.isEdit) {
        this.form.once_max_quantity =
          product.sku_list[0]?.once_max_quantity ?? 1;
        this.form.once_quantity = product.sku_list[0]?.once_quantity ?? 1;
        this.form.once_increase_quantity =
          product.sku_list[0]?.once_increase_quantity ?? 1;
      }
    },

    "form.order": function () {
      this.form.order = this.form.order.replace(/^0+(?=\d)/, "");

      // 確保輸入的值為數字，如果不是，則設置為空字串
      const parsedValue = parseFloat(this.form.order);
      if (isNaN(parsedValue)) {
        this.form.order = "";
      }
    },
  },

  methods: {
    async getGroupbuying() {
      try {
        const { data } = await groupbuyApi.getGroupbuying(
          this.$route.params.groupbuyID
        );

        const {
          payday_period,
          max_quantity,
          is_force_confirmed,
          once_quantity,
          once_max_quantity,
          once_increase_quantity,
          start_at,
          end_at,
          products,
          group,
          tracking_code,
          order,
        } = data.data;

        this.groupbuying = data.data;

        this.form = {
          ...this.form,
          branch_id: group.branch_id,
          payday_period,
          is_force_confirmed,
          once_quantity,
          once_max_quantity,
          once_increase_quantity,
          group_id: group.id,
          max_quantity,
          tracking_code,
          order: String(order),
          start_at: {
            date: new Date(start_at),
            time: {
              HH: String(getHours(new Date(start_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(start_at))).padStart(2, "0"),
            },
          },
          end_at: {
            date: new Date(end_at),
            time: {
              HH: String(getHours(new Date(end_at))).padStart(2, "0"),
              mm: String(getMinutes(new Date(end_at))).padStart(2, "0"),
            },
          },
        };

        this.code = products[0].sale_page_code;
        // 在編輯模式下，要讓 product 的選項有值，所以 Options 設置為目前團購的 product
        this.productOptions = [products[0]];
        this.selectedProduct = products[0];
        this.form.product_ids = [products[0].id];
        this.selectedGroup = group;
        this.form.group_ids = [group.id];
      } catch (err) {
        console.log("err", err);
      }
    },

    async getBelowBranches(branch_id) {
      try {
        const { data } = await branchApi.getCurrentBelowBranches({
          branch_id,
        });

        return data.data.filter(
          (branch) => branch.org_id === this.organization.id
        );
      } catch (error) {
        this.$swal("失敗", error.response.data.message, "error");
        return;
      }
    },

    mapBranches(branches) {
      return branches
        .sort((a, b) => a.level - b.level)
        .sort((a, b) => {
          // if "level" is the same, order by "order"
          if (a.level === b.level) {
            return a.order - b.order;
          }
        })
        .map((branch) => {
          let branchName = `${branch.branch_code} ${branch.name}`;
          if (branch.level === 2) {
            branchName = `- ${branch.branch_code} ${branch.name}`;
          }
          if (branch.level === 3) {
            branchName = `- - ${branch.branch_code} ${branch.name}`;
          }

          return {
            ...branch,
            value: branch.id,
            name: branchName,
          };
        });
    },

    async getGroupbuyingConfig() {
      try {
        const { data } = await groupbuyApi.getGroupbuyingConfig();

        this.useWhichProductSource =
          (data.config &&
            data.config.groupbuying_create &&
            data.config.groupbuying_create.product_source) ??
          "all";
      } catch (err) {
        console.log("err");
      }
    },

    async getFirstLayerBranches() {
      this.isFetchFirstLayerBranches = true;
      const branches = await this.getBelowBranches(this.currentBranch.id);
      this.firstLayerBranches = branches;
      if (branches.length > 1) {
        this.firstLayerBranches = branches.filter(
          (branch) => branch.id !== this.currentBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.firstBranch = this.mapFirstLayerBranches[0];
        this.form.branch_id = this.currentBranch.id;
      }

      this.isFetchFirstLayerBranches = false;
    },

    async handleFirstBranch(branch) {
      this.isFetchSecondLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      if (branches.length > 1) {
        this.secondLayerBranches = branches.filter(
          (branch) => branch.id !== this.firstBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.secondLayerBranches = [];
        this.form.branch_id = branch.id;
      }
      this.isFetchSecondLayerBranches = false;
    },

    async handleSecondBranch(branch) {
      this.isFetchThirdLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      this.thirdLayerBranches = branches;
      if (branches.length > 1) {
        this.thirdLayerBranches = branches.filter(
          (branch) => branch.id !== this.secondBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.form.branch_id = branch.id;
      }
      this.isFetchThirdLayerBranches = false;
    },

    async handleThirdBranch(branch) {
      this.form.branch_id = branch.id;
    },

    async getGroups() {
      try {
        this.isFetchGroup = true;
        const { data } = await groupApi.getGroups({
          status: 1,
          branch_id: this.form.branch_id,
        });

        if (data.data.length === 0) {
          this.$swal("失敗", "此分店無相關群組", "error");
          this.isFetchGroup = false;
          return;
        }

        this.groupOptions = data.data;
        this.form.group_ids = this.groupOptions.map((group) => group.id); // 預設把所有群組都打勾
      } catch (err) {
        console.log("err");
      }
      this.isFetchGroup = false;
    },

    async getProducts() {
      try {
        this.isFetchProduct = true;
        // console.log(new Date());
        const { data } = await groupbuyApi.getGroupBuyEventProducts({
          branch_id: this.form.branch_id
        });
        this.productOptions = data.data;
        if (this.productOptions.length === 1) {
          this.form.product_ids = [this.productOptions[0].id];
          this.selectedProduct = this.productOptions[0];
        }
      } catch (error) {
        console.error(error);
      }
      this.isFetchProduct = false;
    },
    async handleSearchProducts() {
      if (!this.code) {
        return;
      }
      try {
        this.isFetchProduct = true;
        const { data } = await groupbuyApi.getGroupBuyProducts(
          this.code,
          this.currentBranch.id
        );

        if (data.data.is_closed) {
          throw new Error("is_closed");
        }
        if (isBefore(new Date(data.data.available_end_at), new Date())) {
          throw new Error("available_end_at", {
            cause: data.data.available_end_at,
          });
        }
        this.selectedProduct = data.data;
        this.form.product_ids = [this.selectedProduct.id];
        this.form.once_max_quantity =
          this.selectedProduct.sku_list[0]?.once_max_quantity ?? 1;
        this.form.once_quantity =
          this.selectedProduct.sku_list[0]?.once_quantity ?? 1;
        this.form.once_increase_quantity =
          this.selectedProduct.sku_list[0]?.once_increase_quantity ?? 1;
      } catch (error) {
        this.selectedProduct = null;
        let errorMessage;
        switch (error.message) {
          case "is_closed":
            errorMessage = `您輸入的商品頁編號：${this.code} <br/>商品已關閉`;
            break;
          case "available_end_at":
            errorMessage = `您輸入的商品頁編號：${
              this.code
            } <br/>商品已於 ${format(
              new Date(error.cause),
              "yyyy-MM-dd HH:mm"
            )} 結束銷售`;
            break;
          default:
            errorMessage = `您輸入的商品頁編號：${this.code} <br/>查無此商品`;
        }
        this.$swal({
          type: "error",
          html: errorMessage,
        }).then(() => {
          this.code = null;
        });
      }
      this.isFetchProduct = false;
    },

    handleSelectProduct(product) {
      this.selectedProduct = product;
      this.form.product_ids = [product.id];
    },

    handleCancelGroupbuy() {
      this.$router.push({ name: "GroupbuyingsList" });
    },

    validateProductTime() {
      if (!this.selectedProduct) return false;
      const groupbuyStartAt = new Date(
        format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy/MM/dd HH:mm:ss" // for safari data format compatible
        )
      );
      const groupbuyEndAt = new Date(
        format(
          set(new Date(this.form.end_at.date), {
            hours: Number(this.form.end_at.time.HH),
            minutes: Number(this.form.end_at.time.mm),
            seconds: 0,
          }),
          "yyyy/MM/dd HH:mm:ss"
        )
      );

      /**
       * 產品銷售時間 start <= 開團時間 start <= 產品銷售時間 end
       * 產品銷售時間 start <= 開團時間 end <= 產品銷售時間 end
       */
      return (
        isWithinInterval(groupbuyStartAt, {
          start: new Date(this.selectedProduct.available_start_at),
          end: new Date(this.selectedProduct.available_end_at),
        }) &&
        isWithinInterval(groupbuyEndAt, {
          start: new Date(this.selectedProduct.available_start_at),
          end: new Date(this.selectedProduct.available_end_at),
        })
      );
    },

    async handleSubmitGroupbuy() {
      const result = await this.v$.$validate();
      console.log(this.v$.$errors);
      let isProductTimeValid = true;
      if (this.selectedProduct) {
        isProductTimeValid = await this.validateProductTime();
      }

      if (!isProductTimeValid) {
        this.$swal({
          type: "error",
          html: `團購時間已超出商品的銷售時間<br/>(${format(
            new Date(this.selectedProduct.available_start_at),
            "yyyy-MM-dd HH:mm"
          )} ~ ${format(
            new Date(this.selectedProduct.available_end_at),
            "yyyy-MM-dd HH:mm"
          )})<br/>無法新增團購`,
          animation: false,
          customClass: "animated tada",
        });
      }
      if (!result || !isProductTimeValid) return;

      const form = {
        ...this.form,
        start_at: format(
          set(new Date(this.form.start_at.date), {
            hours: Number(this.form.start_at.time.HH),
            minutes: Number(this.form.start_at.time.mm),
            seconds: 0,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
        end_at: format(
          set(new Date(this.form.end_at.date), {
            hours: this.form.end_at.time.HH,
            minutes: this.form.end_at.time.mm,
            seconds: 59,
          }),
          "yyyy-MM-dd HH:mm:ss"
        ),
      };

      if (this.isEdit) {
        this.handleUpdateGroupbuy(form);
      } else {
        this.handleCreateGroupbuy(form);
      }
    },

    async handleUpdateGroupbuy(form) {
      try {
        this.isSubmmiting = true;
        await groupbuyApi.updateGroupbuying(
          this.$route.params.groupbuyID,
          form
        );
        this.$swal
          .fire({
            icon: "success",
            text: "更新成功",
          })
          .then(() => {
            this.$router.push({ name: "GroupbuyingsList" });
          });
      } catch (error) {
        this.$swal({
          type: "error",
          html: error.response.data.message,
        });
      }
      this.isSubmmiting = false;
    },

    async handleCreateGroupbuy(form) {
      try {
        this.isSubmmiting = true;
        await groupbuyApi.createGroupbuy(form);
        this.$swal
          .fire({
            icon: "success",
            text: "新增成功",
          })
          .then(() => {
            this.$router.push({ name: "GroupbuyingsList" });
          });
      } catch (error) {
        console.log("");
      }
      this.isSubmmiting = false;
    },
  },
};
</script>

<style>
/* Default override (not using "append-to-body") */
.vdp-datepicker input.is-invalid,
.vue__time-picker input.is-invalid {
  border: 1px solid #dc3545 !important;
}

.vdp-datepicker input:disabled,
.vue__time-picker input:disabled {
  background-color: #e9ecef !important;
  color: #212529 !important;
}
</style>
